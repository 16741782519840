:root {
  --desktop_nav_height: 100px;
  --mobile_nav_height: 80px;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Roboto", sans-serif;
}

a {
  color: inherit;
  text-decoration: none !important;
}

.anchor {
  scroll-margin-top: var(--desktop_nav_height);

  @media (max-width: 1000px) {
    scroll-margin-top: var(--mobile_nav_height);
  }
}

* {
  box-sizing: border-box;
}

body *:not(.slick-track):not(.slick-list) {
  transition: all ease 0.3s;
}

.glass {
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

/* Register slider */

.register-slider > .slick-list {
  height: 100%;
  .slick-track {
    height: 100%;
    .slick-slide {
      height: 100%;
      div {
        height: 100%;
      }
    }
  }
}

/* Testimonials slider */

.slick-dots ul {
  width: fit-content !important;
}

.slick-dots li {
  margin: 0 16px !important;
}

.slick-dots li button:before {
  font-size: 8px !important;
  color: var(--chakra-colors-textGray) !important;
}

.slick-arrow {
  display: none !important;
}
